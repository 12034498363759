import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import Title from "../components/title"
import VideoBlock from "../components/video-block"
import IconLink from "../components/icon-link"
import Masory from "../components/masory"
import { graphql } from "gatsby"
import PreFooter from "../components/pre-footer"
import Faqs from "../components/faqs"
import Testimonial from "../components/testimonial"
import { Helmet } from 'react-helmet'
import FeatureBenefits from "../components/feature-benefits"

const ClassesYoga = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero');
  const dataTestimonial = _data.find((item: any) => item.block === 'testimonial')
  const dataFAQ = _data.find((item: any) => item.block === 'faqs')
  const dataIconLink = _data.find((item: any) => item.block === 'icon-link')
  const featureBenefits = _data.find((item: any) => item.block === 'feature-benefits')
  return (
    <Layout>
      <Helmet
          title='Classess active yoga'
        />
        <Hero background="bg-blue-white" data={hero.data[0]} container={true}></Hero>
        <IconLink data={dataIconLink.data[0]} background="bg-white"></IconLink>
        <Title data={dataTitle.data[0]} background="bg-white"  className="pt-0"></Title>
        <VideoBlock 
          video="https://vimeo.com/93003441"
          title="Watch the 2 minute tour" 
          background="bg-white" 
          thumbnail="/images/place-holder-16x9.jpg" />
        <FeatureBenefits data={featureBenefits.data[0].feature_benefits} background="bg-white" noSpacing={true}></FeatureBenefits>
        <Title data={dataTitle.data[1]} background="bg-white" ></Title>
        <Testimonial vertical={true} data={dataTestimonial.data[0]} background="bg-white"></Testimonial>
        <Title data={dataTitle.data[2]} background="bg-white" ></Title>
        <Masory ></Masory>
        <Title data={dataTitle.data[3]} background="bg-white" ></Title>
        <Faqs data={dataFAQ.data[0]}></Faqs>
        <PreFooter data={dataPrefooter.data[0]} background="bg-blue"></PreFooter>
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
query {
  allSampleDataJson(filter: {jsonName: {in: ["classes-yoga"]}}) {
    nodes {
      jsonName
      data {
        block
        data {
          hero {
            hight_light
            content
            images {
              id
              url
              width
            }
            title
            title_top
          }
          title_data {
            title
            content
          }
          text_media {
            title
            content
            image
            video {
              thumbnail
              title
              video
            }
            links{
              id
              text
              url
            }
          }
          cards {
            id
            title
            image
            link
          }
          prefooter_data {
            content
            title
            links {
              id
              text
              url
            }
          }
          icons {
            icon_link {
              id
              title
              image
              link
            }
          }
          feature_benefits {
            content
            image
            title
            id
          }
          faq {
            name
            faq_cta
            faq_link
            questions {
              id
              faq_title
              faq_contents
            }
          }
          testimonial {
            id
            author
            address
            content
            image
            bgColor
          }
          logo {
            link
            title
            images {
              id
              url
            }
          }
        }
      }
    }
  }
}
`
export default ClassesYoga
